import React, { useContext, useEffect } from 'react';
import {
  Badge,
  BadgeText,
  Box,
  Button,
  ButtonText,
  Center,
  HStack,
  Heading,
  Input,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  ActionsheetItemText,
  Actionsheet,
  InputField,
  ActionsheetItem,
  Divider,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
  Toast,
  VStack,
  ToastTitle,
  Switch,
  Alert,
  AlertText,
  Pressable,
  Avatar,
  AvatarImage,
  AvatarGroup,
  InputSlot,
  InputIcon,
  ButtonGroup,
  Image,
} from '@gluestack-ui/themed';

import { dashboardAPI as DashboardAPI, dashboardAPI } from '../DashboardAPI';

import { AuthContext } from '../AuthProvider';
import CryptoAvatar from './shared/CryptoAvatar';
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import Loading from './shared/Loading';
import Ionicons from 'react-native-vector-icons/Ionicons';

const CheckMark = require('../assets/checkmark.png');

export default function AddFunds({ navigation, route }) {
  const {
    user,
    marketApps,
    playSettings,
    cryptoInvestSettings,
    marketAppsLoading,
  } = useContext(AuthContext);

  const [playType, setPlayType] = React.useState(
    playSettings === 'NONE' ? 'BULL' : playSettings,
  );
  let marketApp1 = marketApps.length > 0 ? marketApps[0] : 'robinhood';

  if ('robinhood_stocks' === marketApp1 || 'robinhood_crypto' === marketApp1) {
    marketApp1 = 'robinhood';
  }
  const [marketApp, setMarketApp] = React.useState(marketApp1);
  const cryptoo =
    marketApp1 === 'robinhood'
      ? dashboardAPI.getCryptosFromMarketList(
          dashboardAPI.getPossibleRobinhoodMarkets(marketApps),
        )
      : dashboardAPI.getCryptosFromMarket(marketApp1);
  const entitiesInvestedSet = new Set();
  for (const key in cryptoInvestSettings) {
    if (cryptoInvestSettings[key] !== 'dont_invest') {
      entitiesInvestedSet.add(key);
    }
  }

  const [cryptos, setCryptos] = React.useState(cryptoo);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fundExpiringSetting, setFundExpiringSetting] =
    React.useState('non_expirable');
  const [amountInUsd, setAmountInUsd] = React.useState('10.0');
  const [amountInUsdInt, setAmountInUsdInt] = React.useState(10);
  const [quantityOfFunds, setQuantityOfFunds] = React.useState(10);
  const [currency, setCurrency] = React.useState('usd');
  const [maxFunds, setMaxFunds] = React.useState(10);
  const [purpose, setPurpose] = React.useState('anything');
  const [viewState, setViewState] = React.useState('adding_funds');
  const [purposeList, setPurposeList] = React.useState([]);
  const [fundAmount, setFundAmount] = React.useState(1);
  const [showPurposeSelectionSheet, setShowPurposeSelectionSheet] =
    React.useState(false);
  const [showPlayTypeSelectionSheet, setShowPlayTypeSelectionSheet] =
    React.useState(false);
  const [showFundConfigSelectionSheet, setShowFundConfigSelectionSheet] =
    React.useState(false);
  const [showMarketSelectionSheet, setShowMarketSelectionSheet] =
    React.useState(false);

  const [showCurrencySelectionSheet, setShowCurrencySelectionSheet] =
    React.useState(false);

  const [showStrategySlider, setShowStrategySlider] = React.useState(false);
  const [buyingPower, setBuyingPower] = React.useState(null);
  const [sumOfExistingOpenFunds, setSumOfExistingOpenFunds] =
    React.useState(null);

  const [investmentFilter, setInvestmentFilter] = React.useState('lists');

  const toast = useToast();

  const allCryptos = {};

  for (let i = 0; i < cryptoo.length; i = i + 1) {
    allCryptos[cryptoo[i]] = false;
  }
  const [cryptoSelect, setCryptoSelect] = React.useState(allCryptos);

  const handleCryptoSelection = (asset2) => {
    console.log('handle crypto selection ', asset2);
    let select = { ...cryptoSelect };
    if (['any_crypto', 'anything', 'any_etf', 'any_stock'].includes(asset2)) {
      for (let i = 0; i < cryptos.length; i++) {
        select[cryptos[i]] = false;
      }
      setPurpose(asset2);
      setShowPurposeSelectionSheet(false);
    } else if (DashboardAPI.STOCK_CATEGORIES.includes(asset2)) {
      let list_of_companies = dashboardAPI.getInvestmentEntitiesForFilter(
        entitiesInvestedSet,
        marketApps,
        asset2,
      );
      for (let i = 0; i < cryptos.length; i++) {
        if (list_of_companies.includes(cryptos[i])) {
          select[cryptos[i]] = true;
        } else {
          select[cryptos[i]] = false;
        }
      }
      setPurpose('custom_list');
      setShowPurposeSelectionSheet(false);
    } else {
      setPurpose('custom_list');
      select[asset2] = !cryptoSelect[asset2];
    }

    setCryptoSelect(select);
  };

  const isAnyCryptoSelect = () => {
    for (let i = 0; i < cryptos.length; i = i + 1) {
      if (cryptoSelect[cryptos[i]]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      ///TODO
      getBuyingPower(marketApp);
      getSumOfExistingOpenFunds(marketApp);
    })();
  }, [marketApp, viewState, marketAppsLoading]);

  const getBuyingPower = (market2) => {
    setBuyingPower(null);
    dashboardAPI.getBuyingPower(user, market2).then((x) => {
      console.log('buying power', parseFloat(x.data.buying_power));
      setBuyingPower(parseFloat(x.data.buying_power));
    });
  };
  const getSumOfExistingOpenFunds = (market2) => {
    setSumOfExistingOpenFunds(null);
    // dashboardAPI.getLapsedFunds(user).then((x) => {
    //   if (market2 === 'robinhood') {
    //     setSumOfExistingOpenFunds(x.data.total_open_cash_usd);
    //   }
    //   if (market2 === 'bitso') {
    //     setSumOfExistingOpenFunds(x.data.total_open_cash_mxn);
    //   }
    // });
  };

  const handleClosePlayTypeSelection = (asset2) => {
    setPlayType(asset2);
    setShowPlayTypeSelectionSheet(false);
  };

  const handleCloseFundConfigSelection = (asset2) => {
    setFundExpiringSetting(asset2);
    setShowFundConfigSelectionSheet(false);
  };

  const handleCloseCurrencySelection = (newCurrency) => {
    setCurrency(newCurrency);
    setShowCurrencySelectionSheet(false);
    if (newCurrency === 'cop') {
      setAmountInUsd('20000.00');
      setAmountInUsdInt(20000);
      setFundAmount('5000.00');
      setQuantityOfFunds(4);
      setMaxFunds(4);
    }
    if (newCurrency === 'mxn') {
      setAmountInUsd('200.00');
      setAmountInUsdInt(200);
      setFundAmount('20.00');
      setQuantityOfFunds(5);
      setMaxFunds(5);
    }
    if (newCurrency === 'usd') {
      setAmountInUsd('10.00');
      setAmountInUsdInt(10);
      setFundAmount('1.00');
      setQuantityOfFunds(10.0);
      setMaxFunds(10);
    }
  };

  const handleCloseMarketSelection = (asset2) => {
    if (asset2 !== marketApp) {
      let newCryptos = null;
      if (asset2 === 'robinhood') {
        newCryptos = dashboardAPI.getCryptosFromMarketList(
          dashboardAPI.getPossibleRobinhoodMarkets(marketApps),
        );
      } else {
        newCryptos = dashboardAPI.getCryptosFromMarket(asset2);
      }
      const allCryptos2 = {};

      for (let i = 0; i < newCryptos.length; i = i + 1) {
        allCryptos2[newCryptos[i]] = false;
      }
      setCryptoSelect(allCryptos2);
      setCryptos(newCryptos);
      setPurpose('anything');
    }
    setMarketApp(asset2);
    setShowMarketSelectionSheet(false);
    setCurrency('usd');
    setInvestmentFilter('lists');

    setAmountInUsd('10.00');
    setAmountInUsdInt(10);
    setFundAmount('1.00');
    setQuantityOfFunds(10.0);
    setMaxFunds(10);
  };

  const setAmountInUsdAndUpdateTotalInvestment = (newAmountInUsd) => {
    if (isNaN(Number(newAmountInUsd))) {
      return;
    }
    setAmountInUsd(newAmountInUsd);
    setAmountInUsdInt(parseInt(newAmountInUsd));

    if (currency === 'usd') {
      setQuantityOfFunds(parseInt(newAmountInUsd));
      setFundAmount('1.00');
      setMaxFunds(parseInt(newAmountInUsd));
    } else if (currency == 'mxn') {
      setQuantityOfFunds(parseInt(parseInt(newAmountInUsd) / 20.0));
      setFundAmount('20.00');
      setMaxFunds(parseInt(newAmountInUsd) / 20.0);
    } else if (currency == 'cop') {
      setQuantityOfFunds(parseInt(parseInt(newAmountInUsd) / 5000.0));
      setFundAmount('5000.00');
      setMaxFunds(parseInt(newAmountInUsd) / 5000.0);
    }
  };
  const setQuantityOfFundsAndUpdateTotalInvestment = (newQuantity) => {
    setQuantityOfFunds(newQuantity);

    setFundAmount((parseFloat(amountInUsd) / newQuantity).toFixed(2));
  };
  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  const handleSubmit = () => {
    setIsLoading(true);
    let cryptos_to_invest = [];
    if (purpose === 'custom_list') {
      for (let i = 0; cryptos.length > i; i++) {
        if (cryptoSelect[cryptos[i]]) {
          cryptos_to_invest.push(cryptos[i]);
        }
      }
    } else {
      cryptos_to_invest.push(purpose);
    }

    const value = {
      // user: profile.email,
      status: 'open',
      play_type: playType,
      amount_in_fiat_currency: fundAmount,
      number_of_funds_to_make: quantityOfFunds,
      market_app: marketApp,
      currency: currency,
      fund_expiring_setting: fundExpiringSetting,
      cryptos_to_invest: cryptos_to_invest,
    };
    if (value.play_type === undefined || value.play_type === null) {
      value.play_type = 'BULL';
    }
    if (
      typeof value.amount_in_fiat_currency === 'string' &&
      !value.amount_in_fiat_currency.endsWith('.00')
    ) {
      value.amount_in_fiat_currency = (
        Math.round(parseFloat(value.amount_in_fiat_currency) * 100) / 100
      ).toFixed(2);
    }
    dashboardAPI
      .postFunds(user, value)
      .then((x) => {
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="info" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Funds Created!</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
        setViewState('funds_added');
        setIsLoading(false);
      })
      .catch(() => {
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable To Create Funds</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
        setIsLoading(false);
      });
  };

  return (
    <SafeAreaView style={{ height: '100%', width: '100%' }}>
      <ScrollView>
        {isLoading || marketAppsLoading ? (
          <Center>
            <Loading></Loading>
          </Center>
        ) : (
          <>
            {viewState === 'funds_added' && (
              <Box style={styles.card}>
                <Center>
                  <Image
                    alt={'Green Checkmarkt'}
                    size={'md'}
                    borderRadius="$none"
                    source={CheckMark}
                  />

                  <Heading size={'2xl'} mt={3}>
                    ${amountInUsd + ' ' + currency.toUpperCase()}
                  </Heading>
                  <Text>Allocated For Trading!</Text>
                  <ButtonGroup mt={10}>
                    <Button
                      variant={'outline'}
                      action={'secondary'}
                      onPress={() => navigation.navigate('Home')}
                    >
                      <ButtonText>Done</ButtonText>
                    </Button>
                  </ButtonGroup>
                </Center>
              </Box>
            )}
            {viewState === 'adding_funds' && (
              <Box style={styles.card}>
                <Center>
                  {dashboardAPI.getPossibleBrokers(marketApps).length > 1 && (
                    <Button
                      size={'lg'}
                      variant={'outline'}
                      action={'primary'}
                      onPress={() => setShowMarketSelectionSheet(true)}
                    >
                      <ButtonText>
                        {capitalizeFirstLetter(marketApp)}
                      </ButtonText>
                    </Button>
                  )}

                  <Input
                    w={'97%'}
                    aria-label={'Amount To Invest'}
                    variant="underlined"
                    size="xl"
                    type={'text'}
                    value={amountInUsd}
                  >
                    <InputSlot pl="$3">
                      <Ionicons name={'logo-usd'} color={'black'} />
                    </InputSlot>
                    <InputField
                      value={amountInUsd}
                      onChangeText={setAmountInUsdAndUpdateTotalInvestment}
                      style={{
                        textAlign: 'center',
                      }}
                    />
                  </Input>
                  <Text size={'sm'}>Amount In {currency.toUpperCase()}</Text>

                  <>
                    <Heading size={'sm'}>Invest funds in?</Heading>
                    {purpose === 'anything' && (
                      <Button
                        size={'lg'}
                        variant={'outline'}
                        action={'primary'}
                        onPress={() => setShowPurposeSelectionSheet(true)}
                      >
                        <ButtonText>
                          {marketApp === 'robinhood' &&
                            marketApps &&
                            marketApps.includes('robinhood_stocks') &&
                            'EVERYTHING'}
                          {marketApp === 'robinhood' &&
                            marketApps &&
                            !marketApps.includes('robinhood_stocks') &&
                            'ALL CRYPTOS'}
                          {['alpaca'].includes(marketApp) &&
                            marketApps &&
                            'ALL STOCKS'}
                          {['bitso', 'binance'].includes(marketApp) &&
                            marketApps &&
                            'ALL CRYPTOS'}
                        </ButtonText>
                      </Button>
                    )}
                    {purpose === 'any_stock' && (
                      <Button
                        size={'lg'}
                        variant={'outline'}
                        action={'primary'}
                        onPress={() => setShowPurposeSelectionSheet(true)}
                      >
                        <ButtonText>STOCKS</ButtonText>
                      </Button>
                    )}
                    {purpose === 'any_etf' && (
                      <Button
                        size={'lg'}
                        variant={'outline'}
                        action={'primary'}
                        onPress={() => setShowPurposeSelectionSheet(true)}
                      >
                        <ButtonText>ETFS</ButtonText>
                      </Button>
                    )}
                    {purpose === 'any_crypto' && (
                      <Button
                        size={'lg'}
                        variant={'outline'}
                        action={'primary'}
                        onPress={() => setShowPurposeSelectionSheet(true)}
                      >
                        <ButtonText>ALL CRYPTOS</ButtonText>
                      </Button>
                    )}
                    {![
                      'any_crypto',
                      'anything',
                      'any_etf',
                      'any_stock',
                    ].includes(purpose) && (
                      <>
                        {isAnyCryptoSelect() ? (
                          <Button
                            onPress={() => setShowPurposeSelectionSheet(true)}
                          >
                            <ButtonText>
                              {' '}
                              {marketApp === 'robinhood' &&
                                marketApps &&
                                marketApps.includes('robinhood_stocks') &&
                                'Please Select Stocks/Cryptos/ETFs'}
                              {marketApp === 'robinhood' &&
                                marketApps &&
                                !marketApps.includes('robinhood_stocks') &&
                                'Please Select Cryptos'}
                              {marketApp === 'alpaca' &&
                                marketApps &&
                                'Please Select Stocks'}
                              {['binance', 'bitso'].includes(marketApp) &&
                                marketApps &&
                                'Please Select Cryptos'}
                            </ButtonText>
                          </Button>
                        ) : (
                          <Pressable
                            onPress={() => setShowPurposeSelectionSheet(true)}
                          >
                            <AvatarGroup>
                              {cryptos
                                .filter((crypto2) => cryptoSelect[crypto2])
                                .map((crypto) => (
                                  <Avatar
                                    bgColor="white"
                                    borderColor="white"
                                    key={crypto}
                                  >
                                    <AvatarImage
                                      source={
                                        dashboardAPI.RH_SUPPORTED_STOCKS.has(
                                          crypto,
                                        )
                                          ? dashboardAPI.cryptoIconMap.FACTORY
                                          : dashboardAPI.cryptoIconMap[crypto]
                                      }
                                    ></AvatarImage>
                                  </Avatar>
                                ))}
                            </AvatarGroup>
                          </Pressable>
                        )}
                      </>
                    )}
                  </>

                  <HStack mt={10} style={{ alignItems: 'center' }}>
                    <Heading size={'sm'}>Settings</Heading>
                    <Switch
                      size="md"
                      value={showStrategySlider}
                      onToggle={() =>
                        setShowStrategySlider(!showStrategySlider)
                      }
                      style={{ marginLeft: '10px' }}
                    />
                  </HStack>

                  {showStrategySlider && (
                    <>
                      {marketApp === 'bitso' && (
                        <>
                          <Heading size={'sm'}>Currency</Heading>

                          <Button
                            size={'lg'}
                            variant={'outline'}
                            action={'primary'}
                            onPress={() => setShowCurrencySelectionSheet(true)}
                          >
                            <ButtonText>{currency.toUpperCase()}</ButtonText>
                          </Button>
                        </>
                      )}

                      <Center>
                        <Heading size={'sm'}>Invest Mode</Heading>
                        <Button
                          size={'sm'}
                          variant={'outline'}
                          action={'primary'}
                          onPress={() => setShowPlayTypeSelectionSheet(true)}
                        >
                          <ButtonText>
                            {dashboardAPI.investSettingsExplanations[playType]}
                          </ButtonText>
                        </Button>
                      </Center>

                      <Center>
                        <Heading size={'sm'}>Funds Configuration</Heading>

                        <Button
                          size={'sm'}
                          variant={'outline'}
                          action={'primary'}
                          onPress={() => setShowFundConfigSelectionSheet(true)}
                        >
                          <ButtonText>
                            {fundExpiringSetting === 'one_time_use'
                              ? 'Expire After Play Completion'
                              : 'Always Make New Plays'}
                          </ButtonText>
                        </Button>
                      </Center>

                      <Heading size={'sm'}>Number of Funds</Heading>
                      <Center w={300} h={100}>
                        <Slider
                          onChange={setQuantityOfFundsAndUpdateTotalInvestment}
                          minValue={1}
                          maxValue={maxFunds}
                          defaultValue={10}
                          size="md"
                          orientation="horizontal"
                          isDisabled={false}
                          isReversed={false}
                          value={quantityOfFunds}
                        >
                          <SliderTrack>
                            <SliderFilledTrack />
                          </SliderTrack>
                          <SliderThumb />
                        </Slider>
                      </Center>

                      <Heading size={'sm'}>Strategy</Heading>
                      {playType !== 'BOTH' && (
                        <Badge
                          size="md"
                          variant="solid"
                          borderRadius="$none"
                          action={playType === 'BULL' ? 'success' : 'error'}
                        >
                          <BadgeText>
                            {quantityOfFunds} 💲{fundAmount} BULL PLAYS
                          </BadgeText>
                        </Badge>
                      )}
                      {playType === 'BOTH' && quantityOfFunds <= 2 && (
                        <Badge
                          size="md"
                          variant="solid"
                          borderRadius="$none"
                          action="success"
                        >
                          <BadgeText>
                            {quantityOfFunds} 💲{fundAmount} BULL PLAYS
                          </BadgeText>
                        </Badge>
                      )}
                      {playType === 'BOTH' && quantityOfFunds > 2 && (
                        <>
                          <Badge
                            size="md"
                            variant="solid"
                            borderRadius="$none"
                            action="success"
                          >
                            <BadgeText>
                              {Math.ceil(quantityOfFunds * (2 / 3))} 💲
                              {fundAmount} BULL PLAYS
                            </BadgeText>
                          </Badge>
                          <Badge
                            mt={5}
                            size="md"
                            variant="solid"
                            borderRadius="$none"
                            action="error"
                          >
                            <BadgeText>
                              {Math.floor(quantityOfFunds * (1 / 3))} 💲
                              {fundAmount} BEAR PLAYS
                            </BadgeText>
                          </Badge>
                        </>
                      )}
                    </>
                  )}

                  {/*{amountInUsdInt > buyingPower - sumOfExistingOpenFunds && (*/}
                  {/*  <Alert>*/}
                  {/*    <AlertText>*/}
                  {/*      Investment exceeds available funds on{' '}*/}
                  {/*      {marketApp === 'robinhood' ? 'Robinhood' : 'Bitso'}. Deposit*/}
                  {/*      more money and try again.*/}
                  {/*    </AlertText>*/}
                  {/*  </Alert>*/}
                  {/*)}*/}

                  <HStack mt={30} space={'2xl'}>
                    <Button
                      size="lg"
                      variant="solid"
                      action="primary"
                      isFocusVisible={false}
                      isDisabled={
                        isNaN(Number(amountInUsd)) ||
                        Number(amountInUsd) < 1 ||
                        isNaN(Number(quantityOfFunds)) ||
                        Number(quantityOfFunds) < 1 ||
                        (isAnyCryptoSelect() && purpose === 'custom_list')
                      }
                      onPress={() => handleSubmit()}
                    >
                      <ButtonText>Allocate Funds</ButtonText>
                    </Button>
                  </HStack>
                </Center>
              </Box>
            )}
          </>
        )}

        <Actionsheet
          isOpen={showPurposeSelectionSheet}
          onClose={() =>
            setShowPurposeSelectionSheet(!showPurposeSelectionSheet)
          }
          zIndex={999}
          snapPoints={[50]}
          useRNModal={true}
        >
          <ActionsheetBackdrop />
          <ActionsheetContent zIndex={999}>
            <ActionsheetDragIndicatorWrapper>
              <ActionsheetDragIndicator />
            </ActionsheetDragIndicatorWrapper>
            <ActionsheetSectionHeaderText>
              Investments
            </ActionsheetSectionHeaderText>
            {(marketApp === 'robinhood' &&
              marketApps &&
              marketApps.includes('robinhood_stocks')) ||
              (marketApp === 'alpaca' && marketApps && (
                <View style={{ height: '15%', width: '95%' }}>
                  <ScrollView
                    horizontal={true}
                    contentContainerStyle={{
                      paddingHorizontal: 20,
                      paddingBottom: 12,
                      paddingTop: 2,
                    }}
                  >
                    <ButtonGroup>
                      <Button
                        onPress={() => setInvestmentFilter('lists')}
                        variant={
                          investmentFilter === 'lists' ? 'solid' : 'outline'
                        }
                      >
                        <ButtonText>Lists</ButtonText>
                      </Button>
                      <Button
                        onPress={() => setInvestmentFilter('stocks')}
                        variant={
                          investmentFilter === 'stocks' ? 'solid' : 'outline'
                        }
                      >
                        <ButtonText>Stocks</ButtonText>
                      </Button>
                      {marketApps.includes('robinhood_crypto') &&
                        marketApp !== 'alpaca' && (
                          <Button
                            onPress={() => setInvestmentFilter('crypto')}
                            variant={
                              investmentFilter === 'crypto'
                                ? 'solid'
                                : 'outline'
                            }
                          >
                            <ButtonText>Cryptos</ButtonText>
                          </Button>
                        )}
                      {marketApp !== 'alpaca' && (
                        <Button
                          onPress={() => setInvestmentFilter('etfs')}
                          variant={
                            investmentFilter === 'etfs' ? 'solid' : 'outline'
                          }
                        >
                          <ButtonText>ETFS</ButtonText>
                        </Button>
                      )}
                    </ButtonGroup>
                  </ScrollView>
                </View>
              ))}
            <View style={{ height: '80%', width: '95%' }}>
              <ScrollView>
                {marketApps &&
                  ((((marketApp === 'robinhood' &&
                    marketApps.includes('robinhood_stocks')) ||
                    marketApp === 'alpaca') &&
                    investmentFilter === 'lists') ||
                    ['bitso', 'binance'].includes(marketApp) ||
                    (marketApp === 'robinhood' &&
                      marketApps.includes('robinhood_crypto') &&
                      !marketApps.includes('robinhood_stocks'))) && (
                    <Pressable
                      key={'hello'}
                      width={'98%'}
                      onPress={() => handleCryptoSelection('anything')}
                    >
                      <HStack mt={10} borderWidth={3} borderRadius={'$full'}>
                        <Avatar
                          size={'lg'}
                          bgColor="white"
                          borderColor="white"
                        ></Avatar>
                        <Heading>
                          {marketApp === 'robinhood' &&
                            marketApps &&
                            marketApps.includes('robinhood_stocks') &&
                            'ALL STOCKS/ETFS/CRYPTOS'}
                          {marketApp === 'robinhood' &&
                            marketApps &&
                            !marketApps.includes('robinhood_stocks') &&
                            'ALL CRYPTOS'}
                          {marketApp === 'alpaca' && marketApps && 'ALL STOCKS'}
                          {['bitso', 'binance'].includes(marketApp) &&
                            marketApps &&
                            'ALL CRYPTOS'}
                        </Heading>
                      </HStack>
                    </Pressable>
                  )}

                {marketApp === 'robinhood' &&
                  marketApps &&
                  marketApps.includes('robinhood_stocks') &&
                  investmentFilter === 'lists' && (
                    <Pressable
                      key={'hello4'}
                      width={'98%'}
                      onPress={() => handleCryptoSelection('any_stock')}
                    >
                      <HStack mt={10} borderWidth={3} borderRadius={'$full'}>
                        <Avatar
                          size={'lg'}
                          bgColor="white"
                          borderColor="white"
                        ></Avatar>
                        <Heading>STOCKS</Heading>
                      </HStack>
                    </Pressable>
                  )}
                {marketApp === 'robinhood' &&
                  marketApps &&
                  marketApps.includes('robinhood_stocks') &&
                  investmentFilter === 'lists' && (
                    <Pressable
                      key={'hello3'}
                      width={'98%'}
                      onPress={() => handleCryptoSelection('any_etf')}
                    >
                      <HStack mt={10} borderWidth={3} borderRadius={'$full'}>
                        <Avatar
                          size={'lg'}
                          bgColor="white"
                          borderColor="white"
                        ></Avatar>
                        <Heading>ETFS</Heading>
                      </HStack>
                    </Pressable>
                  )}
                {marketApp === 'robinhood' &&
                  marketApps &&
                  ((marketApps.includes('robinhood_crypto') &&
                    !marketApps.includes('robinhood_stocks')) ||
                    (marketApps.includes('robinhood_crypto') &&
                      marketApps.includes('robinhood_stocks') &&
                      investmentFilter === 'lists')) && (
                    <Pressable
                      key={'hello1'}
                      width={'98%'}
                      onPress={() => handleCryptoSelection('any_crypto')}
                    >
                      <HStack mt={10} borderWidth={3} borderRadius={'$full'}>
                        <Avatar
                          size={'lg'}
                          bgColor="white"
                          borderColor="white"
                        ></Avatar>
                        <Heading>CRYPTOS</Heading>
                      </HStack>
                    </Pressable>
                  )}

                {((marketApp === 'alpaca' && marketApps) ||
                  (marketApp === 'robinhood' &&
                    marketApps &&
                    marketApps.includes('robinhood_stocks'))) &&
                  investmentFilter === 'lists' && (
                    <>
                      {DashboardAPI.STOCK_CATEGORIES.filter((category) =>
                        dashboardAPI.checkIfInvestingInFilter(
                          entitiesInvestedSet,
                          marketApps,
                          category,
                        ),
                      ).map((category) => (
                        <Pressable
                          key={'hello' + category}
                          width={'98%'}
                          onPress={() => handleCryptoSelection(category)}
                        >
                          <HStack
                            mt={10}
                            borderWidth={3}
                            borderRadius={'$full'}
                          >
                            <Avatar
                              size={'lg'}
                              bgColor="white"
                              borderColor="white"
                            ></Avatar>
                            <Heading>{category}</Heading>
                          </HStack>
                        </Pressable>
                      ))}
                    </>
                  )}

                {cryptos
                  .filter((crypto) => entitiesInvestedSet.has(crypto))
                  .filter((crypto) => {
                    if (
                      marketApp === 'robinhood' &&
                      investmentFilter === 'etfs'
                    ) {
                      return DashboardAPI.RH_SUPPORTED_ETFS.has(crypto);
                    } else if (
                      ['alpaca', 'robinhood'].includes(marketApp) &&
                      investmentFilter === 'stocks'
                    ) {
                      return (
                        !DashboardAPI.RH_SUPPORTED_ETFS.has(crypto) &&
                        DashboardAPI.RH_SUPPORTED_STOCKS.has(crypto)
                      );
                    } else if (
                      marketApp === 'robinhood' &&
                      investmentFilter === 'crypto'
                    ) {
                      return !dashboardAPI.RH_SUPPORTED_STOCKS.has(crypto);
                    } else if (
                      ['alpaca', 'robinhood'].includes(marketApp) &&
                      investmentFilter === 'lists'
                    ) {
                      return false;
                    }
                    return true;
                  })

                  .map((crypto) => (
                    <Pressable
                      key={crypto}
                      width={'98%'}
                      onPress={() => handleCryptoSelection(crypto)}
                    >
                      <HStack
                        mt={10}
                        borderColor={
                          cryptoSelect[crypto] ? '$secondary600' : '$grey900'
                        }
                        backgroundColor={
                          cryptoSelect[crypto] ? '$secondary500' : '#fff'
                        }
                        borderWidth={3}
                        borderRadius={'$full'}
                      >
                        <CryptoAvatar crypto={crypto} />
                        <Heading
                          color={cryptoSelect[crypto] ? 'white' : 'black'}
                        >
                          {crypto} -{' '}
                          {dashboardAPI.CRYPTO_CONFIG.fullNames[crypto]}
                        </Heading>
                      </HStack>
                    </Pressable>
                  ))}
                {isAnyCryptoSelect() &&
                  ((marketApp === 'robinhood' &&
                    marketApps &&
                    !marketApps.includes('robinhood_stocks')) ||
                    marketApp !== 'robinhood') && <Text>Cryptos</Text>}

                {isAnyCryptoSelect() &&
                  marketApp === 'robinhood' &&
                  marketApps &&
                  marketApps.includes('robinhood_stocks') && (
                    <Text>Cryptos/Stocks/ETFs</Text>
                  )}
                {isAnyCryptoSelect() &&
                  marketApp === 'alpaca' &&
                  marketApps && <Text>Stocks</Text>}
                <Pressable
                  key={'hello13'}
                  width={'98%'}
                  disabled={isAnyCryptoSelect()}
                  invalid={isAnyCryptoSelect()}
                  onPress={() => setShowPurposeSelectionSheet(false)}
                >
                  <HStack
                    mt={20}
                    mb={20}
                    borderColor={'$primary600'}
                    backgroundColor={
                      isAnyCryptoSelect() ? '$primary300' : '$primary500'
                    }
                    borderWidth={3}
                    borderRadius={'$full'}
                  >
                    <Avatar
                      backgroundColor={
                        isAnyCryptoSelect() ? '$primary300' : '$primary500'
                      }
                      size={'lg'}
                    ></Avatar>
                    <Heading mt={10} color="white">
                      {'DONE'}
                    </Heading>
                  </HStack>
                </Pressable>
              </ScrollView>
            </View>
          </ActionsheetContent>
        </Actionsheet>

        <Actionsheet
          isOpen={showPlayTypeSelectionSheet}
          onClose={() =>
            setShowPlayTypeSelectionSheet(!showPlayTypeSelectionSheet)
          }
          zIndex={999}
          snapPoints={[50]}
          useRNModal={true}
        >
          <ActionsheetBackdrop />
          <ActionsheetContent zIndex={999}>
            <ActionsheetDragIndicatorWrapper>
              <ActionsheetDragIndicator />
            </ActionsheetDragIndicatorWrapper>
            <ActionsheetSectionHeaderText>
              Invest Mode
            </ActionsheetSectionHeaderText>

            <View style={{ height: '80%', width: '95%' }}>
              <ScrollView>
                <ActionsheetItem
                  key={'BULL MARKET'}
                  onPress={() => handleClosePlayTypeSelection('BULL')}
                >
                  <ActionsheetItemText>
                    {dashboardAPI.investSettingsExplanations['BULL']}
                  </ActionsheetItemText>
                </ActionsheetItem>
                <ActionsheetItem
                  key={'BEAR MARKET'}
                  onPress={() => handleClosePlayTypeSelection('BEAR')}
                >
                  <ActionsheetItemText>
                    {dashboardAPI.investSettingsExplanations['BEAR']}
                  </ActionsheetItemText>
                </ActionsheetItem>
                <ActionsheetItem
                  key={'SIDEWAYS MARKET'}
                  onPress={() => handleClosePlayTypeSelection('BOTH')}
                >
                  <ActionsheetItemText>
                    BOTH - {dashboardAPI.investSettingsExplanations['BOTH']}
                  </ActionsheetItemText>
                </ActionsheetItem>
              </ScrollView>
            </View>
          </ActionsheetContent>
        </Actionsheet>

        <Actionsheet
          isOpen={showFundConfigSelectionSheet}
          onClose={() =>
            setShowFundConfigSelectionSheet(!showFundConfigSelectionSheet)
          }
          zIndex={999}
          snapPoints={[50]}
          useRNModal={true}
        >
          <ActionsheetBackdrop />
          <ActionsheetContent zIndex={999}>
            <ActionsheetDragIndicatorWrapper>
              <ActionsheetDragIndicator />
            </ActionsheetDragIndicatorWrapper>
            <ActionsheetSectionHeaderText>
              Fund Expiration
            </ActionsheetSectionHeaderText>
            <ActionsheetItem
              onPress={() => handleCloseFundConfigSelection('one_time_use')}
            >
              <ActionsheetItemText>
                Expire After Play Completion
              </ActionsheetItemText>
            </ActionsheetItem>

            <ActionsheetItem
              onPress={() => handleCloseFundConfigSelection('non_expirable')}
            >
              <ActionsheetItemText>Always Make New Plays</ActionsheetItemText>
            </ActionsheetItem>
          </ActionsheetContent>
        </Actionsheet>

        <Actionsheet
          isOpen={showMarketSelectionSheet}
          onClose={() => setShowMarketSelectionSheet(!showMarketSelectionSheet)}
          zIndex={999}
          snapPoints={[50]}
          useRNModal={true}
        >
          <ActionsheetBackdrop />
          <ActionsheetContent zIndex={999}>
            <ActionsheetDragIndicatorWrapper>
              <ActionsheetDragIndicator />
            </ActionsheetDragIndicatorWrapper>
            <ActionsheetSectionHeaderText>
              Crypto Broker
            </ActionsheetSectionHeaderText>
            {marketApps.includes('robinhood') && (
              <ActionsheetItem
                onPress={() => handleCloseMarketSelection('robinhood')}
              >
                <ActionsheetItemText>Robinhood</ActionsheetItemText>
              </ActionsheetItem>
            )}
            {marketApps.includes('bitso') && (
              <ActionsheetItem
                onPress={() => handleCloseMarketSelection('bitso')}
              >
                <ActionsheetItemText>Bitso</ActionsheetItemText>
              </ActionsheetItem>
            )}

            {marketApps.includes('binance') && (
              <ActionsheetItem
                onPress={() => handleCloseMarketSelection('binance')}
              >
                <ActionsheetItemText>Binance</ActionsheetItemText>
              </ActionsheetItem>
            )}
            {marketApps.includes('alpaca') && (
              <ActionsheetItem
                onPress={() => handleCloseMarketSelection('alpaca')}
              >
                <ActionsheetItemText>Alpaca</ActionsheetItemText>
              </ActionsheetItem>
            )}
          </ActionsheetContent>
        </Actionsheet>

        <Actionsheet
          isOpen={showCurrencySelectionSheet}
          onClose={() =>
            setShowCurrencySelectionSheet(!showCurrencySelectionSheet)
          }
          zIndex={999}
          snapPoints={[50]}
          useRNModal={true}
        >
          <ActionsheetBackdrop />
          <ActionsheetContent zIndex={999}>
            <ActionsheetDragIndicatorWrapper>
              <ActionsheetDragIndicator />
            </ActionsheetDragIndicatorWrapper>
            <ActionsheetSectionHeaderText>
              Currency
            </ActionsheetSectionHeaderText>
            <ActionsheetItem
              onPress={() => handleCloseCurrencySelection('usd')}
            >
              <ActionsheetItemText>🇺🇸USD</ActionsheetItemText>
            </ActionsheetItem>

            <ActionsheetItem
              onPress={() => handleCloseCurrencySelection('mxn')}
            >
              <ActionsheetItemText>🇲🇽MXN</ActionsheetItemText>
            </ActionsheetItem>

            {/*<ActionsheetItem*/}
            {/*  onPress={() => handleCloseCurrencySelection('cop')}*/}
            {/*>*/}
            {/*  <ActionsheetItemText>🇨🇴COP</ActionsheetItemText>*/}
            {/*</ActionsheetItem>*/}
          </ActionsheetContent>
        </Actionsheet>
      </ScrollView>
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
