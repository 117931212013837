import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonText,
  Center,
  Heading,
  HStack,
  Pressable,
  Text,
} from '@gluestack-ui/themed';
import { ScrollView, View } from 'react-native';
import { dashboardAPI as DashboardAPI, dashboardAPI } from '../../DashboardAPI';
import CryptoAvatar from './CryptoAvatar';
import { AuthContext } from '../../AuthProvider';
import { useNavigation } from '@react-navigation/native';
import Loading from './Loading';

export default function SelectCryptos(params) {
  const { user, marketApps, marketAppsLoading, refreshMarketApps } =
    useContext(AuthContext);
  useEffect(() => {}, [cryptoList]);
  useEffect(() => {
    if (marketApps.length === 0 && !marketAppsLoading) {
      refreshMarketApps(user);
      return;
    }

    const cryptos2 = dashboardAPI.getCryptosFromMarketList(marketApps);

    const allCryptos2 = {};

    for (let i = 0; i < cryptos2.length; i = i + 1) {
      if (params.settings !== undefined) {
        if (params.settings[cryptos2[i]] === 'dont_invest') {
          allCryptos2[cryptos2[i]] = false;
        } else {
          allCryptos2[cryptos2[i]] = true;
        }
      } else {
        allCryptos2[cryptos2[i]] = false;
      }
    }
    setCryptoList(cryptos2);
    setFilter('all');
    setCryptoSelect(allCryptos2);
  }, [marketAppsLoading, marketApps]);

  const navigation = useNavigation();
  const allCryptos = {};
  const cryptos = dashboardAPI.getCryptosFromMarketList(marketApps);
  for (let i = 0; i < cryptos.length; i = i + 1) {
    if (params.settings !== undefined) {
      if (params.settings[cryptos[i]] === 'dont_invest') {
        allCryptos[cryptos[i]] = false;
      } else {
        allCryptos[cryptos[i]] = true;
      }
    } else {
      allCryptos[cryptos[i]] = false;
    }
  }
  const [cryptoSelect, setCryptoSelect] = React.useState(allCryptos);
  const [cryptoList, setCryptoList] = React.useState(cryptos);
  const [filter, setFilter] = React.useState('all');

  const handleCryptoSelection = (asset2) => {
    let select = { ...cryptoSelect };

    select[asset2] = !cryptoSelect[asset2];
    setCryptoSelect(select);
  };

  const isAnyCryptoSelect = () => {
    for (let i = 0; i < cryptos.length; i = i + 1) {
      if (cryptoSelect[cryptos[i]]) {
        return false;
      }
    }
    return true;
  };

  const updateSelectionList = (filter2) => {
    let cryptos2 = null;
    let allCryptos2 = {};
    if (filter2 === 'all') {
      cryptos2 = dashboardAPI.getCryptosFromMarketList(marketApps);
    } else {
      cryptos2 = dashboardAPI.getCryptosFromMarketListWithFilter(
        marketApps,
        filter2,
      );
    }
    setCryptoList(cryptos2);
    setFilter(filter2);
  };

  const selectAll = () => {
    let select = { ...cryptoSelect };

    for (let i = 0; i < cryptoList.length; i++) {
      select[cryptoList[i]] = true;
    }
    setCryptoSelect(select);
  };

  const submitCryptoSelection = () => {
    const cryptos_to_invest = [];
    const cryptos_to_not_invest = [];
    for (let i = 0; i < dashboardAPI.CRYPTO_CONFIG.names.length; i = i + 1) {
      if (
        cryptos.includes(dashboardAPI.CRYPTO_CONFIG.names[i]) &&
        cryptoSelect[dashboardAPI.CRYPTO_CONFIG.names[i]]
      ) {
        cryptos_to_invest.push(dashboardAPI.CRYPTO_CONFIG.names[i]);
      } else {
        cryptos_to_not_invest.push(dashboardAPI.CRYPTO_CONFIG.names[i]);
      }
    }

    dashboardAPI
      .postCryptoInvestSettingsList(
        user,
        cryptos_to_invest,
        cryptos_to_not_invest,
      )
      .then((x) => {
        refreshMarketApps(user);
        if (params.settings !== undefined) {
          navigation.navigate('Home');
        } else {
          navigation.navigate('AddFunds');
        }
      });
  };

  return (
    <>
      {params.settings === undefined && (
        <Center>
          <Text bold={true} color={'$primary500'}>
            CryptoPilot.app
          </Text>
        </Center>
      )}
      <Text>CryptoPilot will invest in selected items</Text>
      {(marketApps.includes('robinhood_stocks') ||
        marketApps.includes('alpaca')) && (
        <>
          <View
            styles={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <ScrollView
              horizontal={true}
              contentContainerStyle={{
                paddingHorizontal: 20,
                paddingBottom: 12,
                paddingTop: 10,
              }}
            >
              <Heading mr={10}>Filters</Heading>
              <HStack space={'sm'}>
                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('all')}
                  variant={filter === 'all' ? 'solid' : 'outline'}
                >
                  <ButtonText>ALL</ButtonText>
                </Button>
                {marketApps.includes('robinhood_crypto') && (
                  <Button
                    size={'lg'}
                    borderRadius="$full"
                    onPress={() => updateSelectionList('crypto')}
                    variant={filter === 'crypto' ? 'solid' : 'outline'}
                  >
                    <ButtonText>CRYPTO</ButtonText>
                  </Button>
                )}
                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('stocks')}
                  variant={filter === 'stocks' ? 'solid' : 'outline'}
                >
                  <ButtonText>STOCKS</ButtonText>
                </Button>

                {marketApps.includes('robinhood_stocks') && (
                  <Button
                    size={'lg'}
                    borderRadius="$full"
                    onPress={() => updateSelectionList('etfs')}
                    variant={filter === 'etfs' ? 'solid' : 'outline'}
                  >
                    <ButtonText>ETFs</ButtonText>
                  </Button>
                )}

                {DashboardAPI.STOCK_CATEGORIES.map((cat) => (
                  <Button
                    key={cat}
                    size={'lg'}
                    borderRadius="$full"
                    onPress={() => updateSelectionList(cat)}
                    variant={filter === cat ? 'solid' : 'outline'}
                  >
                    <ButtonText>{cat}</ButtonText>
                  </Button>
                ))}
              </HStack>
            </ScrollView>
          </View>
          {!isAnyCryptoSelect() && (
            <Button onPress={() => submitCryptoSelection()} m={10}>
              <ButtonText>Save</ButtonText>
            </Button>
          )}
        </>
      )}

      <Button onPress={() => selectAll()} m={10}>
        <ButtonText>Select All Below</ButtonText>
      </Button>

      {!marketAppsLoading ? (
        <ScrollView width={'100%'}>
          {cryptoList.map((crypto) => (
            <Pressable
              key={crypto}
              width={'98%'}
              onPress={() => handleCryptoSelection(crypto)}
            >
              <HStack
                mt={10}
                borderColor={
                  cryptoSelect[crypto] ? '$secondary600' : '$grey900'
                }
                backgroundColor={
                  cryptoSelect[crypto] ? '$secondary500' : '#fff'
                }
                borderWidth={3}
                borderRadius={'$full'}
              >
                <CryptoAvatar crypto={crypto} />
                <Heading color={cryptoSelect[crypto] ? 'white' : 'black'}>
                  {crypto} - {DashboardAPI.CRYPTO_CONFIG.fullNames[crypto]}
                </Heading>
              </HStack>
            </Pressable>
          ))}
        </ScrollView>
      ) : (
        <Loading />
      )}
      {isAnyCryptoSelect() && <Text>Select One Item</Text>}
      <Button
        isDisabled={isAnyCryptoSelect()}
        onPress={() => submitCryptoSelection()}
        m={10}
      >
        <ButtonText>Save</ButtonText>
      </Button>
    </>
  );
}
