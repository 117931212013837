import React, { useContext, useEffect } from 'react';
import {
  Center,
  Heading,
  Box,
  Button,
  Avatar,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  RadioLabel,
  RadioIndicator,
  RadioIcon,
  CircleIcon,
  Actionsheet,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  Alert,
  AlertIcon,
  AlertText,
  ButtonText,
  CheckCircleIcon,
  Icon,
  AtSignIcon,
  Text,
  ActionsheetItem,
  ActionsheetItemText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputSlot,
  InputIcon,
  InputField,
  Image,
  useToast,
  Toast,
  ToastTitle,
} from '@gluestack-ui/themed';
import { dashboardAPI } from '../DashboardAPI';
import { AuthContext } from '../AuthProvider';
import CryptoAvatar from './shared/CryptoAvatar';
import {
  SafeAreaView,
  ScrollView,
  View,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
} from 'react-native';
import Loading from './shared/Loading';
import { useNavigation } from '@react-navigation/native';
import firebaseUtils from '../Firebase';
import Login from './shared/Login';

const RobinhoodIcon = require('../assets/bitso.png');

export default function AlpacaLogin({ route }) {
  const { user, marketApps, logout, marketAppsLoading, refreshMarketApps } =
    useContext(AuthContext);
  const navigation = useNavigation();

  const [alpacaAPIKey, setAlpacaAPIKey] = React.useState('');
  const [alpacaAPISecret, setAlpacaAPISecret] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const toast = useToast();

  useEffect(() => {
    (async () => {})();
  }, [user]);

  const login = () => {
    setLoading(true);

    const value = {
      alpaca_api_secret: alpacaAPISecret,
      alpaca_api_key: alpacaAPIKey,
      market_app: 'alpaca',
    };

    value['alpaca_api_key'] = dashboardAPI.encryptString(
      value['alpaca_api_key'],
    );
    value['alpaca_api_secret'] = dashboardAPI.encryptString(
      value['alpaca_api_secret'],
    );

    dashboardAPI
      .postUser(user, value)
      .then((x) => {
        if (x.data.ok === 'ok') {
          toast.show({
            placement: 'top',
            render: ({ id }) => {
              return (
                <Toast nativeId={id} action="success" variant="solid">
                  <VStack space="xs">
                    <ToastTitle>
                      Alpaca Account Configured Successfully
                    </ToastTitle>
                  </VStack>
                </Toast>
              );
            },
          });
          navigation.navigate('Home');
          refreshMarketApps(user);
        }
      })
      .catch((err) => {
        console.log(err);

        setLoading(false);
        setAlpacaAPIKey('');
        setAlpacaAPISecret('');
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable to Login into Alpaca</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      });
  };

  return (
    <SafeAreaView>
      {loading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <>
          {user !== false ? (
            <Box>
              <Center style={styles.card}>
                {/*<Image*/}
                {/*  style={{ height: 120, width: 330 }}*/}
                {/*  alt={'Robinhood Icon'}*/}
                {/*  size={'xl'}*/}
                {/*  borderRadius="$none"*/}
                {/*  source={RobinhoodIcon}*/}
                {/*/>*/}

                {/*<Heading size={'sm'}>Need Help??</Heading>*/}

                {/*<Button*/}
                {/*  onPress={() => navigation.navigate('AlpacaGuide')}*/}
                {/*  variant={'outline'}*/}
                {/*>*/}
                {/*  <ButtonText>Step By Step Guide</ButtonText>*/}
                {/*</Button>*/}

                <VStack w="$full" p={20}>
                  <FormControl mt={36}>
                    <FormControlLabel>
                      <FormControlLabelText>
                        Alpaca API Key
                      </FormControlLabelText>
                    </FormControlLabel>
                    <Input aria-label={'Alpaca API Key'} size={'lg'} w="$full">
                      <InputField
                        value={alpacaAPIKey}
                        onChangeText={setAlpacaAPIKey}
                        variant="rounded"
                      />
                    </Input>
                    <FormControlLabel>
                      <FormControlLabelText>
                        Alpaca API Secret
                      </FormControlLabelText>
                    </FormControlLabel>
                    <Input
                      aria-label={'Alpaca API Secret'}
                      size={'lg'}
                      w="$full"
                    >
                      <InputField
                        value={alpacaAPISecret}
                        onChangeText={setAlpacaAPISecret}
                        variant="rounded"
                      />
                    </Input>

                    <Button
                      isDisabled={alpacaAPIKey === '' || alpacaAPISecret === ''}
                      onPress={() => login()}
                      mt={20}
                    >
                      <ButtonText>Sign In</ButtonText>
                    </Button>
                  </FormControl>
                </VStack>
              </Center>
            </Box>
          ) : (
            <Center style={styles.card}>
              <Heading mb={20}>Alpaca API Config Page</Heading>
              <Text>
                To continue configuring Alpaca API, Login to your
                CryptoPilot.app Account
              </Text>
              <Login redirect={'AlpacaLogin'} />
            </Center>
          )}
        </>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
