import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonText,
  Divider,
  Text,
  Center,
  Heading,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  ActionsheetItem,
  ActionsheetItemText,
  Actionsheet,
  Switch,
  HStack,
  Alert,
  AlertIcon,
  InfoIcon,
  AlertText,
  Box,
} from '@gluestack-ui/themed';

import { dashboardAPI } from '../DashboardAPI';
import Play from './shared/Play';
import { FlatList, ScrollView, TouchableOpacity, View } from 'react-native';
import { AuthContext } from '../AuthProvider';
import { SafeAreaView, StyleSheet } from 'react-native';
import Loading from './shared/Loading';
import CryptoAvatar from './shared/CryptoAvatar';
import { useIsFocused } from '@react-navigation/native';

export default function PlayFeed({ navigation }) {
  const isFocused = useIsFocused();

  const { user, marketApps } = useContext(AuthContext);

  const [recentPlays, setRecentPlays] = React.useState([]);
  const [openPlays, setOpenPlays] = React.useState([]);
  const [completedOrOpenPlaysToggle, setCompletedOrOpenPlaysToggle] =
    React.useState('completed');

  const [viewFilters, setViewFilters] = React.useState(false);
  const [showCryptoSelectionSheet, setShowCryptoSelectionSheet] =
    React.useState(false);
  const [showMarketSelectionSheet, setShowMarketSelectionSheet] =
    React.useState(false);
  const [showPlayTypeSelectionSheet, setShowPlayTypeSelectionSheet] =
    React.useState(false);
  const [crypto, setCrypto] = React.useState('ALL');
  const [cryptos, setCryptos] = React.useState(
    dashboardAPI.getCryptosFromMarketList(marketApps),
  );
  const [playType, setPlayType] = React.useState('ALL');
  const [marketApp, setMarketApp] = React.useState('ALL');
  const [loading, setLoading] = React.useState(true);

  const [all_cryptos_and_stocks, setAll_cryptos_and_stocks] = React.useState(
    marketApps.includes('robinhood_stocks') ? 'All Investments' : 'All Cryptos',
  );

  const handleClosePlayTypeSelection = (asset2) => {
    setPlayType(asset2);
    setShowPlayTypeSelectionSheet(false);
    setRecentPlays([]);
    setOpenPlays([]);
    loadFunds(crypto, asset2, marketApp);
  };

  const handleCloseCryptoSelection = (asset2) => {
    setCrypto(asset2);
    setShowCryptoSelectionSheet(false);
    setRecentPlays([]);
    setOpenPlays([]);
    loadFunds(asset2, playType, marketApp);
  };

  const handleCloseMarketSelection = (asset2) => {
    setMarketApp(asset2);
    setShowMarketSelectionSheet(false);
    setRecentPlays([]);
    setOpenPlays([]);
    loadFunds(crypto, playType, asset2);
  };

  async function loadFunds(crypto2, playType2, marketApp2) {
    setLoading(true);
    dashboardAPI.playFeed(user, crypto2, playType2, marketApp2).then((x) => {
      if (x.data.recent_plays !== null) {
        setRecentPlays(x.data.recent_plays);
      }
      if (x.data.recently_opened_plays !== null) {
        setOpenPlays(x.data.recently_opened_plays);
      }
      setLoading(false);
    });
  }

  const viewAddFunds = () => {
    navigation.navigate('AddFunds', {});
  };

  useEffect(() => {
    if (isFocused) {
      (async () => {
        if (user) {
          loadFunds(crypto, playType, marketApp);
        }
      })();
    } else {
      setLoading(true);
    }
  }, [isFocused]);

  return (
    <>
      <SafeAreaView style={{ flex: 8 }}>
        <View
          styles={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <ScrollView
            horizontal={true}
            contentContainerStyle={{
              paddingHorizontal: 20,
              paddingBottom: 12,
              paddingTop: 10,
            }}
          >
            <Heading mr={10}>Plays</Heading>
            <HStack space={'sm'}>
              <Button
                size={'lg'}
                borderRadius="$full"
                onPress={() => setCompletedOrOpenPlaysToggle('completed')}
                variant={
                  completedOrOpenPlaysToggle === 'completed'
                    ? 'solid'
                    : 'outline'
                }
              >
                <ButtonText>Completed</ButtonText>
              </Button>

              <Button
                size={'lg'}
                borderRadius="$full"
                onPress={() => setCompletedOrOpenPlaysToggle('open')}
                variant={
                  completedOrOpenPlaysToggle === 'open' ? 'solid' : 'outline'
                }
              >
                <ButtonText>Opened</ButtonText>
              </Button>
            </HStack>
          </ScrollView>
        </View>

        {!viewFilters && (
          <HStack mt={10}>
            <Heading ml={20}>Filters</Heading>
            <Switch
              mt={6}
              value={viewFilters}
              onToggle={() => setViewFilters(!viewFilters)}
            />
          </HStack>
        )}
        {viewFilters && (
          <View
            mt={10}
            styles={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <ScrollView horizontal={true}>
              <HStack>
                <Heading ml={20}>Filters</Heading>
                <Switch
                  mt={6}
                  value={viewFilters}
                  onToggle={() => setViewFilters(!viewFilters)}
                />
              </HStack>

              <Center mt={10}>
                <Button
                  size={'lg'}
                  borderRadius="$full"
                  w={'200px'}
                  variant={crypto === 'ALL' ? 'outline' : 'solid'}
                  action={'primary'}
                  onPress={() => setShowCryptoSelectionSheet(true)}
                >
                  {crypto === 'ALL' ? (
                    <></>
                  ) : (
                    <CryptoAvatar size={'sm'} crypto={crypto} />
                  )}
                  <ButtonText>
                    {crypto === 'ALL' ? all_cryptos_and_stocks : crypto}
                  </ButtonText>
                </Button>
              </Center>

              <Center mt={10}>
                <Button
                  size={'lg'}
                  borderRadius="$full"
                  w={'200px'}
                  variant={playType === 'ALL' ? 'outline' : 'solid'}
                  action={'primary'}
                  onPress={() => setShowPlayTypeSelectionSheet(true)}
                >
                  <ButtonText>
                    {playType === 'ALL' ? 'All Play Types' : playType + ' Play'}
                  </ButtonText>
                </Button>
              </Center>

              {dashboardAPI.getPossibleBrokers(marketApps).length > 1 && (
                <>
                  <Center mt={10}>
                    <Button
                      size={'lg'}
                      borderRadius="$full"
                      w={marketApp === 'ALL' ? '250px' : '200px'}
                      variant={marketApp === 'ALL' ? 'outline' : 'solid'}
                      action={'primary'}
                      onPress={() => setShowMarketSelectionSheet(true)}
                    >
                      <ButtonText>
                        {marketApp === 'ALL'
                          ? 'All Brokers'
                          : marketApp.toUpperCase()}
                      </ButtonText>
                    </Button>
                  </Center>
                </>
              )}
            </ScrollView>
          </View>
        )}

        {completedOrOpenPlaysToggle === 'completed' && (
          <>
            {loading ? (
              <Center>
                <Loading mt={30} />
              </Center>
            ) : (
              <>
                {recentPlays.length > 0 ? (
                  <FlatList
                    w={'$95'}
                    h={'$90'}
                    data={recentPlays}
                    renderItem={({ x, index }) => {
                      return (
                        <Play
                          key={recentPlays[index]['id']}
                          play={recentPlays[index]}
                          viewtoggle={true}
                        />
                      );
                    }}
                    keyExtractor={(x, index) => recentPlays[index]['id']}
                  />
                ) : (
                  <TouchableOpacity onPress={() => viewAddFunds()}>
                    <Alert mx="$2.5" action="warning" variant="outline">
                      <AlertIcon as={InfoIcon} mr="$3" />
                      <AlertText>
                        No Completed Plays yet. Invest more money to create new
                        Plays
                      </AlertText>
                    </Alert>
                  </TouchableOpacity>
                )}
              </>
            )}
          </>
        )}

        {completedOrOpenPlaysToggle === 'open' && (
          <>
            {loading ? (
              <Center>
                <Loading mt={30} />
              </Center>
            ) : (
              <>
                {openPlays.length > 0 ? (
                  <FlatList
                    w={'$95'}
                    h={'$90'}
                    data={openPlays}
                    renderItem={({ x, index }) => {
                      return (
                        <Play
                          key={openPlays[index]['id']}
                          play={openPlays[index]}
                          viewtoggle={true}
                        />
                      );
                    }}
                    keyExtractor={(x, index) => openPlays[index]['id']}
                  />
                ) : (
                  <TouchableOpacity onPress={() => viewAddFunds()}>
                    <Alert mx="$2.5" action="warning" variant="outline">
                      <AlertIcon as={InfoIcon} mr="$3" />
                      <AlertText>
                        No Created Plays yet. Invest more money to create new
                        Plays
                      </AlertText>
                    </Alert>
                  </TouchableOpacity>
                )}
              </>
            )}
          </>
        )}
      </SafeAreaView>
      <Actionsheet
        isOpen={showCryptoSelectionSheet}
        onClose={() => setShowCryptoSelectionSheet(!showCryptoSelectionSheet)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>Assets</ActionsheetSectionHeaderText>

          <View style={{ height: '80%', width: '95%' }}>
            <ScrollView>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('ALL')}
              >
                <ActionsheetItemText>
                  {all_cryptos_and_stocks}
                </ActionsheetItemText>
              </ActionsheetItem>

              {cryptos.map((crypto) => (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection(crypto)}
                  key={crypto}
                >
                  <CryptoAvatar crypto={crypto} />
                  <ActionsheetItemText>
                    {crypto} - {dashboardAPI.CRYPTO_CONFIG.fullNames[crypto]}
                  </ActionsheetItemText>
                </ActionsheetItem>
              ))}
            </ScrollView>
          </View>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showMarketSelectionSheet}
        onClose={() => setShowMarketSelectionSheet(!showMarketSelectionSheet)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            Crypto Broker
          </ActionsheetSectionHeaderText>
          <ActionsheetItem onPress={() => handleCloseMarketSelection('ALL')}>
            <ActionsheetItemText>All Brokers</ActionsheetItemText>
          </ActionsheetItem>
          {marketApps.includes('robinhood') && (
            <ActionsheetItem
              onPress={() => handleCloseMarketSelection('robinhood')}
            >
              <ActionsheetItemText>Robinhood</ActionsheetItemText>
            </ActionsheetItem>
          )}

          {marketApps.includes('bitso') && (
            <ActionsheetItem
              onPress={() => handleCloseMarketSelection('bitso')}
            >
              <ActionsheetItemText>Bitso</ActionsheetItemText>
            </ActionsheetItem>
          )}

          {marketApps.includes('binance') && (
            <ActionsheetItem
              onPress={() => handleCloseMarketSelection('binance')}
            >
              <ActionsheetItemText>Binance</ActionsheetItemText>
            </ActionsheetItem>
          )}
          {marketApps.includes('alpaca') && (
            <ActionsheetItem
              onPress={() => handleCloseMarketSelection('alpaca')}
            >
              <ActionsheetItemText>Alpaca</ActionsheetItemText>
            </ActionsheetItem>
          )}
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showPlayTypeSelectionSheet}
        onClose={() =>
          setShowPlayTypeSelectionSheet(!showPlayTypeSelectionSheet)
        }
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>Play Type</ActionsheetSectionHeaderText>
          <ActionsheetItem onPress={() => handleClosePlayTypeSelection('ALL')}>
            <ActionsheetItemText>All Plays</ActionsheetItemText>
          </ActionsheetItem>
          <ActionsheetItem onPress={() => handleClosePlayTypeSelection('BULL')}>
            <ActionsheetItemText>Bull Plays</ActionsheetItemText>
          </ActionsheetItem>

          <ActionsheetItem onPress={() => handleClosePlayTypeSelection('BEAR')}>
            <ActionsheetItemText>Bear Plays</ActionsheetItemText>
          </ActionsheetItem>
        </ActionsheetContent>
      </Actionsheet>
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
